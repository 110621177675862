<script>
  import Button, { Label } from "@smui/button";
  import LayoutGrid, { Cell } from "@smui/layout-grid";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import qs from "qs";
  import sleep from "sleep-promise";
  import { onMount, tick } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError, TrackingData } from "~/libs/commonTypes";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import TrackingResult from "~/pages/Tracking/TrackingResult.svelte";

  /**
   * 追跡番号のテキストボックスのmaxlengthデフォルト値
   * @type {number}
   */
  const DEFAULT_MAX_LENGTH_OF_INPUT_TRACKING_NUMBER = 12;

  /**
   * 追跡情報の一覧
   * @type {Array<TrackingData>}
   */
  let trackingDataList = [];
  /**
   * 追跡番号のテキストボックス
   * @type {import("@smui/textfield").default}
   */
  let inputTrackingNumber;
  /**
   * 追跡番号のテキストボックスの値
   * @type {string}
   */
  let inputTrackingNumberValue = "";
  /**
   * 追跡番号のテキストボックスのmaxlength
   * @type {number}
   */
  let maxLengthOfInputTrackingNumber =
    DEFAULT_MAX_LENGTH_OF_INPUT_TRACKING_NUMBER;
  /**
   * 追跡番号のテキストボックスの入力桁数（数字のみ）
   * @type {number}
   */
  let numberCountOfInputTrackingNumberValue = 0;

  // SvelteのonMountイベントハンドラ
  onMount(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    console.log("[Main.svelte] Query Parameters:", queryParams);

    if (queryParams.n) {
      /* クエリパラメータに追跡番号が入っている場合 */
      loadingProgress.wrapAsync(async () => {
        try {
          // 追跡番号のチェック＆正規化(map)と重複削除(filter)
          const trackingNumbers = []
            .concat(queryParams.n)
            .map((v) => checkAndNormalizeTrackingNumber(v))
            .filter((v, i, self) => self.indexOf(v) === i);
          console.log("[Main.svelte] Tracking Numbers:", trackingNumbers);

          // 5件ごとに追跡を実行（5件超は5件ごとに5秒スリープ）
          const newTrackingDataList = [];
          for (let i = 0; i < trackingNumbers.length; ) {
            if (i > 0) await sleep(5000);
            const next = Math.min(i + 5, trackingNumbers.length);
            newTrackingDataList.push(
              ...(await trackingAll(trackingNumbers.slice(i, next))),
            );
            i = next;
          }
          for (let i = 0; i < newTrackingDataList.length; i++) {
            newTrackingDataList[i].order = i + 1; // 表示順序を追加
          }
          trackingDataList = newTrackingDataList;
        } catch (error) {
          console.log("[Main.svelte] onMount catch Error:", error);
          showErrorToast(error, $_("errors.defaultMessageForTracking"));
        }
      })();
    } else {
      /* クエリパラメータなしの場合 */
      if (inputTrackingNumber) {
        // 追跡番号のテキストボックスにフォーカスを当てる
        inputTrackingNumber.focus();
      }
    }
  });

  /**
   * 追跡番号の形式チェックを行い、正規化（数字以外除去）した追跡番号を返す。
   * @param {string} trackingNumber 追跡番号
   * @returns {string} 正規化済の追跡番号
   */
  function checkAndNormalizeTrackingNumber(trackingNumber) {
    const normalizedTrackingNumber = normalizeTrackingNumber(
      trackingNumber ?? "",
    );
    if (!normalizedTrackingNumber.match(/^[0-9]{12}$/)) {
      throw new HandledError($_("errors.wrongTrackingNumber"));
    }
    return normalizedTrackingNumber;
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   * @param {string} defaultErrorMessage errorがHandledError以外の場合に使用するエラーメッセージ
   */
  function showErrorToast(error, defaultErrorMessage) {
    if (error instanceof HandledError) {
      toast.error(error.message);
    } else {
      if (error instanceof HTTPError && error.response?.status === 400) {
        toast.error($_("errors.wrongTrackingNumber"));
      } else if (error instanceof HTTPError && error.response?.status === 404) {
        toast.error($_("errors.nonexistentTrackingNumber"));
      } else {
        toast.error(defaultErrorMessage);
      }
    }
  }

  /**
   * 複数の荷物の追跡を行い、追跡情報の配列を返す。
   * @param {Array<string>} trackingNumbers 追跡番号の配列
   * @returns {Promise<Array<TrackingData>>} 追跡情報の配列
   */
  async function trackingAll(trackingNumbers) {
    const trackingDataPromises = [];
    for (const trackingNumber of trackingNumbers) {
      trackingDataPromises.push(tracking(trackingNumber));
    }
    console.log(
      "[Main.svelte] trackingAll:",
      trackingNumbers,
      trackingDataPromises,
    );
    return await Promise.all(trackingDataPromises);
  }

  /**
   * 荷物の追跡を行い、追跡情報を返す。
   * @param {string} trackingNumber 追跡番号
   * @returns {Promise<TrackingData>} 追跡情報のPromise
   */
  async function tracking(trackingNumber) {
    const trackingResult = await backendApi.getShipment(trackingNumber);
    if (!trackingResult) {
      throw new Error("Illegal State: empty tracking response");
    }
    const trackingData = new TrackingData();
    trackingData.trackingNumber = trackingNumber;
    trackingData.result = trackingResult;

    // 置き配写真URLのパラメータ名変更に対応するため、古いパラメータ名を新しいパラメータ名に変換する
    // 完全に切り替わったら削除してOK
    if (!trackingData.result.podPhotoUrl) {
      trackingData.result.podPhotoUrl = trackingResult.url;
    }

    console.log("[Main.svelte] tracking:", trackingNumber, trackingData);
    return trackingData;
  }

  /**
   * URLのクエリパラメータへ、問い合わせた追跡番号を追加する。
   * @param {Array<TrackingData>} trackingDataList
   */
  function updateLocationHref(trackingDataList) {
    // 追跡番号のリストからクエリ文字列を生成
    const queryParams = {
      n: Array.from(
        trackingDataList.map((trackingData) => trackingData.trackingNumber),
      ),
    };
    const queryParamsString = qs.stringify(queryParams, { indices: false });

    let newLocationHref;
    if (location.search.length > 0) {
      /* クエリ文字列付きの場合 */
      const baseUrl = location.href.substring(
        0,
        location.href.lastIndexOf(location.search) + 1,
      );
      newLocationHref = `${baseUrl}${queryParamsString}${location.hash}`;
    } else {
      /* クエリ文字列なしの場合 */
      let baseUrl;
      if (location.hash.length > 0) {
        /* ハッシュ付きの場合 */
        baseUrl = location.href.substring(
          0,
          location.href.lastIndexOf(location.hash),
        );
      } else {
        /* ハッシュなしの場合 */
        baseUrl = location.href;
      }
      newLocationHref = `${baseUrl}?${queryParamsString}${location.hash}`;
    }

    // ブラウザーのセッション履歴に新しいURLをプッシュ
    window.history.pushState({}, "", newLocationHref);

    console.log(
      "[Main.svelte] updateLocationHref:",
      trackingDataList,
      queryParams,
      newLocationHref,
    );
  }

  /**
   * お問い合わせ開始ボタンにおけるクリックイベントのハンドラ。
   */
  async function onClickStartTracking() {
    try {
      const trackingNumber = checkAndNormalizeTrackingNumber(
        inputTrackingNumberValue,
      );

      // 追跡番号の重複チェック
      const duplicationIndex = trackingDataList.findIndex(
        (trackingData) => trackingNumber === trackingData.trackingNumber,
      );
      if (duplicationIndex >= 0) {
        toast.error(
          $_("errors.alreadyTracked", {
            values: { order: duplicationIndex + 1 },
          }),
        );
        return;
      }

      await loadingProgress.wrapAsync(async () => {
        const trackingData = await tracking(trackingNumber);
        trackingData.order = trackingDataList.length + 1;
        trackingDataList.push(trackingData);
        trackingDataList = trackingDataList;

        updateLocationHref(trackingDataList);

        inputTrackingNumberValue = "";
      })();
    } catch (error) {
      console.error(error);
      showErrorToast(error, $_("errors.defaultMessageForTracking"));
    }
  }

  /**
   * 追跡番号のテキストボックスにおけるキー押下イベントのハンドラ。
   * @param {KeyboardEvent} event keydownイベントオブジェクト
   */
  function onKeyDownInputTrackingNumber(event) {
    if (event.key === "Enter") {
      onClickStartTracking();
    }
  }

  /**
   * 追跡番号のテキストボックスにおけるペーストイベントのハンドラ。
   * @param {ClipboardEvent} event pasteイベントオブジェクト
   */
  async function onPasteInputTrackingNumber(event) {
    event.preventDefault();

    const inputElement = /** @type {HTMLInputElement} */ (event.target);

    const valuePrefix = inputElement.value.slice(
      0,
      inputElement.selectionStart,
    );
    const valueSuffix = inputElement.value.slice(
      inputElement.selectionEnd,
      inputTrackingNumberValue.length,
    );
    const paste = normalizeTrackingNumber(
      event.clipboardData.getData("text") ?? "",
    );
    const pasteLength = 12 - valuePrefix.length - valueSuffix.length;
    const selectionAfterPaste = inputElement.selectionStart + pasteLength;

    inputTrackingNumberValue =
      valuePrefix + paste.slice(0, pasteLength) + valueSuffix;
    await tick(); // wait for DOM updated
    inputElement.selectionStart = selectionAfterPaste;
    inputElement.selectionEnd = selectionAfterPaste;
  }

  /**
   * 追跡番号のテキストボックスにおける入力イベントのハンドラ。
   */
  function onInputTrackingNumber() {
    const hyphenCount = (inputTrackingNumberValue.match(/-/g) || []).length;

    maxLengthOfInputTrackingNumber =
      DEFAULT_MAX_LENGTH_OF_INPUT_TRACKING_NUMBER + Math.min(hyphenCount, 2);

    numberCountOfInputTrackingNumberValue = (
      inputTrackingNumberValue.match(/[0-9]/g) || []
    ).length;
  }

  /**
   * 追跡番号を正規化（Unicode正規化、数字以外の削除）する。
   * @param {string} trackingNumber 追跡番号
   * @returns {string}
   */
  function normalizeTrackingNumber(trackingNumber) {
    const normalized = trackingNumber.normalize
      ? trackingNumber.normalize("NFKC")
      : trackingNumber;
    return normalized.replace(/[^0-9]/g, "");
  }

  function updateTrackingData(event) {
    /** @type {string} */
    const targetTrackingNumber = event.detail.trackingNumber;
    /** 本人情報確認後に取得した配送状況の問合せ結果 @type {import("~/libs/commonTypes").TrackingResult} */
    const updateData = event.detail.result;
    const index = trackingDataList.findIndex(
      (e) => e.trackingNumber === targetTrackingNumber,
    );

    if (index >= 0) {
      trackingDataList[index].result = updateData;
      trackingDataList = trackingDataList;
    }
  }
</script>

<h1>{$_("pages.Main.title")}</h1>
<div class="hline" />

{#if trackingDataList.length > 0}
  <p class="explanatorText">
    {@html $_("pages.Main.description1")}
  </p>

  {#each trackingDataList as trackingData}
    <TrackingResult {...trackingData} on:identify={updateTrackingData} />
  {/each}

  <p class="explanatorText">
    {$_("pages.Main.description2")}
  </p>
{:else}
  <p class="explanatorText">
    {$_("pages.Main.description3")}
  </p>
{/if}

<div class="text-area">
  <LayoutGrid>
    <Cell span={5}>
      <Textfield
        class="shaped-outlined"
        variant="outlined"
        label={$_("pages.Main.inputTrackingNumberLabel")}
        style="width: 100%;"
        bind:this={inputTrackingNumber}
        bind:value={inputTrackingNumberValue}
        on:keydown={(event) =>
          /* @ts-ignore */ onKeyDownInputTrackingNumber(
            /** @type {KeyboardEvent} */ (event),
          )}
        on:paste={(event) =>
          /* @ts-ignore */ onPasteInputTrackingNumber(
            /** @type {ClipboardEvent} */ (event),
          )}
        on:input={onInputTrackingNumber}
        required
        input$minlength={maxLengthOfInputTrackingNumber}
        input$maxlength={maxLengthOfInputTrackingNumber}
        input$pattern="[0-9]{'{'}4{'}'}-?[0-9]{'{'}4{'}'}-?[0-9]{'{'}4{'}'}"
        input$inputmode="numeric"
      ></Textfield>
      <div class="characterCounter">
        {numberCountOfInputTrackingNumberValue} / {DEFAULT_MAX_LENGTH_OF_INPUT_TRACKING_NUMBER}
      </div>
    </Cell>
    <Cell>
      <Button
        style="font-size: 15px; padding: 28px 20px;"
        color="secondary"
        variant="unelevated"
        disabled={!inputTrackingNumberValue}
        on:click={onClickStartTracking}
      >
        <Label>{$_("pages.Main.trackButtonLabel")}</Label>
      </Button>
    </Cell>
  </LayoutGrid>
</div>

{#if trackingDataList.length === 0}
  <div class="infoArea">
    <div class="contactLabel">
      {$_("pages.Main.contactInfoLabel")}
    </div>
    <div class="contact">
      <span class="telNum"
        ><span class="material-icons">call</span><a href="tel:080-7140-4491"
          >{$_("pages.Main.contactInfoTel")}</a
        ></span
      >
      <div class="receptionTime">
        <span class="heading">{$_("pages.Main.contactInfoReceptionLabel")}</span
        >{$_("pages.Main.contactInfoReceptionTime")}
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  h1 {
    color: #363535;
    font-weight: bold;
    line-height: 1.5;
    margin: 5px 10px;
  }
  .hline {
    background-color: #01878786;
    height: 1px;
  }
  .text-area {
    :global(
      .shaped-outlined .mdc-notched-outline .mdc-notched-outline__leading
    ) {
      border-radius: 28px 0 0 28px;
      width: 28px;
    }
    :global(
      .shaped-outlined .mdc-notched-outline .mdc-notched-outline__trailing
    ) {
      border-radius: 0 28px 28px 0;
    }
    :global(
      .mdc-text-field--focused:not(.mdc-text-field-disabled) .mdc-floating-label
    ) {
      color: #018786;
    }
    :global(
      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled
        ).mdc-text-field--focused
        .mdc-notched-outline__leading
    ) {
      border-color: #018786;
    }
    :global(
      .mdc-text-field--outlined:not(
          .mdc-text-field-disabled
        ).mdc-text-field--focused
        .mdc-notched-outline__notch
    ) {
      border-color: #018786;
    }
    :global(
      .mdc-text-field--outlined:not(
          .mdc-text-field-disabled
        ).mdc-text-field--focused
        .mdc-notched-outline__trailing
    ) {
      border-color: #018786;
    }
    .characterCounter {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.75rem;
      letter-spacing: 0.03em;
      text-align: right;
    }
  }
  .infoArea {
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    margin: 20px auto 0;
    text-align: center;
    width: 90%;
    max-width: 600px;
    .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      .telNum {
        color: #018786;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        .material-icons {
          font-size: 24px;
          margin-right: 2px;
        }
        a {
          color: #018786;
        }
      }
      .receptionTime {
        .heading {
          margin-right: 4px;
        }
      }
    }
  }
  @media screen and (min-width: 810px) {
    h1 {
      font-size: 25px;
    }
    .explanatorText {
      margin: 20px 0px 5px 10px;
      line-height: 1.5;
    }
    .infoArea {
      padding: 15px 10px 10px;
      .contactLabel {
        margin-bottom: 2px;
      }
      .contact {
        gap: 10px;
        .telNum {
          font-size: 1.4rem;
          a {
            cursor: default;
            pointer-events: none;
            text-decoration: none;
          }
        }
        .receptionTime {
          font-size: 0.9rem;
        }
      }
    }
  }
  @media screen and (max-width: 809px) {
    h1 {
      font-size: 18px;
    }
    .explanatorText {
      margin: 10px 0px 5px 5px;
      font-size: smaller;
    }
    .infoArea {
      font-size: 0.8rem;
      padding: 10px 10px 5px;
      .contact {
        flex-direction: column;
        .telNum {
          font-size: 1.2rem;
        }
      }
    }
  }

  .explanatorText {
    line-height: 1.5;
  }
</style>
