<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import { HTTPError } from "ky";
  import { _, locale } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { formatDate, getJstDate } from "~/libs/dateUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/utils";

  /** @type {string} */
  export let trackingNumber;

  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;

  /**
   * 再配達希望日時の表示を切り替える関数
   * @type {Function}
   */
  export let configureLocaleSpecificFormat;

  /**
   * 選択肢として表示する日数
   * @type {number}
   */
  const DATE_NUM = 7;

  /**
   * モーダルの開閉状況
   * @type {boolean}
   */
  let open;

  /**
   * @typedef {{
   *   timeFrame: string,
   *   availability: boolean
   * }} TimeFrameSet 時間帯の選択肢
   * @typedef {{
   *   date: Date,
   *   timeFrameSetList: Array<TimeFrameSet>
   * }} DateAndTimeFrameSet 日付と時間帯の選択肢
   */

  /**
   * 選択肢として表示する日時のリスト
   * @type {Array<DateAndTimeFrameSet>}
   */
  let dateAndTimeFrameList = (() => {
    const now = new Date();
    const dateAndTimeFrameList = [];
    for (let i = 0; i < DATE_NUM; i++) {
      let date = getJstDate(now);
      date.setDate(date.getDate() + i);

      /** @type {Array<TimeFrameSet>} */
      let timeFrameList = [];
      for (
        let j = 0;
        j < result.redeliveryContext.timeFramePreset.length;
        j++
      ) {
        timeFrameList[j] = {
          timeFrame: result.redeliveryContext.timeFramePreset[j],
          availability: false,
        };
      }

      dateAndTimeFrameList[i] = {
        date: date,
        timeFrameSetList: timeFrameList,
      };
    }
    return dateAndTimeFrameList;
  })();

  /**
   * 全時間帯での受け取り不可チェックの状態
   * 全ての時間帯で受け取りができない場合にtrue
   * @type {boolean}
   */
  let isNotAvailableAllTimeFrameCheck = false;

  /**
   * 再配達の希望日時。「日付,時間帯」の形式
   * @type {string}
   */
  let desiredDateTime = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date +
      "," +
      result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
    : ",";

  /**
   * 変更前の再配達の希望日
   * @type {string}
   */
  let currentSpecifiedDate = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
    : "";

  /**
   * 変更前の再配達の希望時間帯
   * @type {string}
   */
  let currentSpecifiedTime = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
    : "";

  /**
   * 変更後の再配達の希望日
   * @type {string}
   */
  $: newSpecifiedDate = desiredDateTime.split(",")[0];

  /**
   * 変更後の再配達の希望時間帯
   * @type {string}
   */
  $: newSpecifiedTime = desiredDateTime.split(",")[1];

  /** @type {string} 荷受人氏名 */
  let name = sessionStorage.getItem("identificationName") ?? "";
  /** @type {string} 荷受人郵便番号 */
  let postcode = sessionStorage.getItem("identificationPostcode") ?? "";
  /** @type {string} 荷受人電話番号 */
  let tel = sessionStorage.getItem("identificationTel") ?? "";

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    isNotAvailableAllTimeFrameCheck = false;
    desiredDateTime = result.specifiedPickupDatetime?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date +
        "," +
        result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
      : ",";
    currentSpecifiedDate = result.specifiedPickupDatetime
      ?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
      : "";
    currentSpecifiedTime = result.specifiedPickupDatetime
      ?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
      : "";

    open = true;
  }
  function closeDialog() {
    desiredDateTime = ",";
    isNotAvailableAllTimeFrameCheck = false;
    open = false;
  }

  /**
   * 配送希望時間をフォーマットする
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @param {import("svelte-i18n").locale} locale svelte-i18nのlocale
   * @returns {string} 時間指定の表示
   */
  function formatTimeFrame(timeFrame, locale) {
    let startTime = Number(timeFrame.substring(0, 2));
    let endTime = Number(timeFrame.substring(2, 4));

    let str = "";

    if (locale === "ja") {
      str = startTime + "時～" + endTime + "時";
    } else {
      let startTimeZone = "AM";
      let endTimeZone = "AM";

      if (startTime > 12) {
        startTime = startTime - 12;
        startTimeZone = "PM";
      }
      if (endTime > 12) {
        endTime = endTime - 12;
        endTimeZone = "PM";
      }

      str =
        startTime +
        ":00 " +
        startTimeZone +
        " - " +
        endTime +
        ":00 " +
        endTimeZone;
    }

    return str;
  }

  /**
   * 再配達の希望日時を更新する
   */
  function updateDesiredDateTime() {
    this.setAttribute("data-mdc-dialog-action", "close");

    /** @type {import("~/libs/commonTypes").DateAndTimeFrame} */
    const newDesiredDateAndTimeFrame = {
      date: newSpecifiedDate,
      timeFrame: newSpecifiedTime,
    };
    /** @type {import("~/libs/commonTypes").SpecifiedPickupDatetime} */
    const newSpecifiedPickupDatetime = {
      desiredRedeliveryDatetime: newDesiredDateAndTimeFrame,
      availablePickupDatetime: [],
    };
    /** @type {import("~/libs/backendApi").PushNotificationMessage} */
    const message = {
      title: $_("push.updatedRedeliveryDatetime.title"),
      body: $_("push.updatedRedeliveryDatetime.body", {
        values: {
          trackingNumber: formatTrackingNumber(trackingNumber),
        },
      }),
    };
    /** @type {import("~/libs/backendApi").PushNotification} */
    const pushNotification = {
      title: message.title,
      body: message.body,
      data: {
        message: message,
        trackingNumber: trackingNumber,
        adjustedRedeliveryDatetime: newDesiredDateAndTimeFrame,
      },
    };
    /** @type {import("~/libs/backendApi").ReceiverIdentification} */
    const receiverIdentification =
      name && postcode && tel
        ? {
            name,
            postcode,
            tel,
          }
        : null;
    loadingProgress.wrapAsync(async () => {
      try {
        await backendApi.updateDesiredRedeliveryDatetime(
          trackingNumber,
          newDesiredDateAndTimeFrame,
          pushNotification,
          receiverIdentification,
        );
        result.specifiedPickupDatetime = newSpecifiedPickupDatetime;
        delete result.redeliveryContext.adjustedRedeliveryDatetime;
        configureLocaleSpecificFormat();
        toast.info(
          $_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.completion"),
        );
        closeDialog();
      } catch (error) {
        showErrorToast(error);
      }
    })();
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error["errorResponse"]?.title === "delivery completed.") {
      toast.error($_("errors.deliveredPackage"));
    } else if (
      error instanceof HTTPError &&
      error.response?.status >= 400 &&
      error.response?.status < 500
    ) {
      console.error(error);
      toast.error($_("errors.invalidDesiredRedeliveryDatetime"));
    } else {
      console.error(error);
      toast.error($_("errors.failedDesiredRedeliveryDatetime"));
    }
  }

  /**
   * noteエリアが表示されるようにダイアログをスクロールする
   */
  function scrollToShowNote() {
    if (isNotAvailableAllTimeFrameCheck) {
      let note = document.getElementsByClassName("note")[0];
      note.scrollIntoView({ behavior: "smooth" });
    }
  }
</script>

<div class="setDesiredRedeliveryDateDialog">
  <Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="set-desired-redelivery-datetime-dialog-title"
    aria-describedby="set-desired-redelivery-datetime-dialog-content"
    style="margin-top: 50px; max-height: 90%"
  >
    <Title id="set-desired-redelivery-datetime-dialog-title"
      >{$_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.title")}</Title
    >
    <Content id="set-desired-redelivery-datetime-dialog-content">
      <p>
        {@html $_(
          "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.description",
        )}
      </p>
      <div class="calendar">
        {#each dateAndTimeFrameList as dateAndTimeFrame, dateIndex}
          <div class="calendarDate">
            <div class="head">
              {#if dateIndex === 0}
                {$_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.today")}
              {/if}
              {formatDate(
                dateAndTimeFrame.date,
                $_("config.defaultDateFormat"),
                $locale,
              )}
            </div>
            {#each dateAndTimeFrame.timeFrameSetList as timeFrameSet}
              <label
                class="timeFrame"
                class:disactive={isNotAvailableAllTimeFrameCheck ||
                  dateIndex === 0}
              >
                <input
                  type="radio"
                  name="desiredDateTime"
                  bind:group={desiredDateTime}
                  value={formatDate(
                    dateAndTimeFrame.date,
                    "yyyy-MM-dd",
                    $locale,
                  ) +
                    "," +
                    timeFrameSet.timeFrame}
                  disabled={isNotAvailableAllTimeFrameCheck || dateIndex === 0}
                />
                <div class="timeFrameInner">
                  {formatTimeFrame(timeFrameSet.timeFrame, $locale)}
                </div>
              </label>
            {/each}
          </div>
        {/each}
      </div>
      <div class="noPickupTimeFrameArea">
        <FormField>
          <Checkbox
            bind:checked={isNotAvailableAllTimeFrameCheck}
            on:change={scrollToShowNote}
          />
          <span slot="label"
            >{$_(
              "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notAvailableAllTimeFrameLabel",
            )}</span
          >
        </FormField>
      </div>
      {#if isNotAvailableAllTimeFrameCheck}
        <div class="note">
          <span class="material-icons md-24">info</span>
          <div class="noteText">
            <div class="noteText_main">
              {@html $_(
                "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notAvailableAllTimeFrameNote",
              )}
            </div>
            <div class="noteText_contact">
              <span>{$_("pages.Main.contactInfoLabel")}</span>
              <span class="tel">
                <span class="material-icons md-22">call</span>
                <a href="tel:080-7140-4491">{$_("pages.Main.contactInfoTel")}</a
                >
              </span>
              <span class="reception"
                >({$_("pages.Main.contactInfoReceptionLabel")}
                {$_("pages.Main.contactInfoReceptionTime")})</span
              >
            </div>
          </div>
        </div>
      {/if}
    </Content>
    <Actions>
      <Button on:click={closeDialog}>
        <Label
          >{$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.cancelButtonLabel",
          )}</Label
        >
      </Button>
      <Button
        on:click={updateDesiredDateTime}
        disabled={isNotAvailableAllTimeFrameCheck ||
          (!isNotAvailableAllTimeFrameCheck &&
            currentSpecifiedDate === newSpecifiedDate &&
            currentSpecifiedTime === newSpecifiedTime)}
      >
        <Label
          >{$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.updateButtonLabel",
          )}</Label
        >
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  p {
    font-size: 15px;
    color: #000;
  }
  .calendar {
    box-sizing: border-box;
    color: #000;
    display: flex;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }
  .calendarDate .head {
    background-color: #018786;
    border: 1px solid #018786;
    color: #fff;
  }
  .calendarDate .timeFrame {
    display: block;
    border-right: 1px solid #018786;
    border-bottom: 1px solid #018786;
    position: relative;
    user-select: none;
  }
  .calendarDate .timeFrame input[type="radio"] {
    position: absolute;
    left: -1000%;
  }
  .calendarDate .timeFrame .timeFrameInner {
    padding: 10px 5px;
  }
  .calendarDate .timeFrame input[type="radio"]:disabled + .timeFrameInner {
    background-color: #d7d7d7;
    color: #6b6b6b;
  }
  .calendarDate .timeFrame input[type="radio"]:checked + .timeFrameInner {
    background-color: #dfc3f3;
  }
  .calendarDate
    .timeFrame
    input[type="radio"]:checked:disabled
    + .timeFrameInner {
    background-color: #d7d7d7;
  }
  .noPickupTimeFrameArea {
    margin-top: 10px;
  }
  .note {
    box-sizing: border-box;
    display: flex;
    margin-top: 10px;
    align-items: center;
    background-color: #ffe7e7;
    font-size: 14px;
    color: #242424;
    border-radius: 5px;
    max-width: 700px;
    min-width: 232px;

    > .material-icons {
      color: #c80000;
      margin: 0 10px;
    }

    .noteText {
      .noteText_contact {
        border-top: 1px solid #979797;
        display: flex;

        .tel {
          display: flex;
          align-items: center;

          .material-icons {
            margin-right: 3px;
          }
          .material-icons.md-22 {
            font-size: 22px;
          }
          a {
            color: #242424;
          }
        }
      }
    }
  }

  @media screen and (min-width: 810px) {
    .setDesiredRedeliveryDateDialog {
      :global(.mdc-dialog .mdc-dialog__surface) {
        max-width: 800px;
      }
    }
    .calendarDate {
      width: 100px;
    }
    .calendarDate:not(:first-of-type) .head {
      border-left: none;
    }
    .calendarDate:first-of-type .timeFrame {
      border-left: 1px solid #018786;
    }
    .calendarDate .timeFrame:not(.disactive):hover {
      cursor: pointer;
    }
    .calendarDate .timeFrame:not(.disactive):hover .timeFrameInner {
      background-color: #f1ebf5;
    }
    .noPickupTimeFrameArea {
      :global(.mdc-checkbox + label:hover) {
        cursor: pointer;
      }
    }
    .note {
      padding: 10px 15px 10px 3px;

      .noteText {
        .noteText_main {
          padding-left: 10px;
        }
        .noteText_contact {
          align-items: center;
          margin-top: 5px;
          padding-top: 5px;
          padding-left: 10px;

          .tel {
            margin-left: 10px;
          }
          .reception {
            margin-left: 8px;
          }
        }
      }
      a {
        cursor: default;
        pointer-events: none;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 809px) {
    .setDesiredRedeliveryDateDialog {
      :global(.mdc-dialog__content) {
        padding: 10px 15px;
      }
    }
    .calendar {
      flex-flow: column;
    }
    .calendarDate:not(:first-of-type) {
      margin-top: 10px;
    }
    .calendarDate .timeFrame {
      border-left: 1px solid #018786;
    }
    .calendarDate .timeFrame .timeFrameInner {
      padding: 5px 2px;
    }
    .note {
      padding: 10px 15px;

      > .material-icons {
        display: none;
      }

      .noteText {
        .noteText_contact {
          flex-flow: column;
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }
</style>
